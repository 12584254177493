.runner-container {
   margin-top: 25px;
}

.star-need {
   font-weight: 500;
   color: #303030;
}

.star-need img {
   margin-right: 15px;
}

.challenge-list {
   display: flex;
   justify-content: space-between;
}

.challenge-left {
   display: flex;
}

.star img {
   margin-right: 100px;
   display: block;
   margin: auto;
}

.star {
   width: 100px;
   margin-right: 7vw;
}

.challenge-person-name {
   display: flex;
}

.challenge-avtar {
   margin-right: 20px;
}

.challenge-person-name h2 {
   font-weight: 500;
   font-size: 17px;
   color: #303030;
   margin-bottom: 8px;
}

.challenge-person-name p {
   font-weight: light;
   font-size: 14px;
}

.challenge-right {
   display: flex;
   margin-right: 8vw;
}

.challenge-right h2 {
   font-weight: 600;
   font-size: 2rem;
}

.challenge-right p {
   font-size: 14px;
}

.coin-icon img {
   margin-right: 10px;
}

@media screen and (max-width: 600px) {
   .persone-name-challenge {
      margin-right: 25px;
   }

   .star {
      width: 100px;
      margin-right: 5px;
   }

   .challenge-right {
      display: flex;
      margin-right: 0px;
      text-align: right;
   }

   .coin-icon {
      display: none;
   }
}