ul .active {
  background-color: #e5e5fe;
  font-weight: 600;
}
@media screen and (max-width: 1029px) {
  .logo-sidebar{
    height: 60px;
  }
  .phoneSidebar{
    left: -100vw;
    transition-duration: 0.5s;
    top: 14px;
    overflow: hidden;
    position: absolute;
  }
  .phoneSidebarOpen{
    left: -5vw;
    transition-duration: 0.5s;
    top: 14px;
    overflow: hidden;
    position: absolute;
  }
  .phoneSidebarContent{
    height: 100vh;
  }
}
