.sign{
   font-weight: bold;
   color: blueviolet;
   font-size: large;
}
.faq-head{
   text-align: center;
   font-size: 5rem;
   color: blueviolet;
   padding-top: 50px;
   margin-bottom: 50px;
}
.faq-main{
   background-image: linear-gradient(to bottom, rgba(68, 102, 68, 0), rgb(255, 255, 255)),url(../../assets/home/home1.svg);
   background-repeat: no-repeat;
   background-size: cover;
}