.outer-footer,
.footer-left {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.outer-footer{
    background: #FFFFFF;
    padding: 5rem 0;
}
#footer-img{
    width: 200px;
}

.footer-left {
    width: 50vw;
}

.footer-text {
    width: 39%;
}

.list-body {
    list-style: none;
    padding: 0%;
    margin: 1rem 0px;
}

.list-head {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */

    /* text-align: center; */

    color: #303030;

}

.list-items {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin: 1rem 0px;
    cursor: pointer;
    /* text-align: center; */
    transition-duration: 0.5s;
    color: #303030;
}

.list-items:hover{
    scale: 1.1;
    transition-duration: 0.5s;
}

.btn-footer {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin: 1rem 0px;
    text-align: center;

    color: #F5F5F5;

    background: #5D44DE;
    border-radius: 5px;
    padding: 1rem 2rem;
    border: none;
}

.lower-footer{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 17px;
/* identical to box height */
height: 2vw;
display: flex;
flex-wrap: wrap;
align-items: center;
justify-content: center;
text-align: center;

color: #FFFFFF;
background: linear-gradient(270deg, #E1E1E1 -0.52%, #9648AD -0.51%, #1D77FF 100.52%);
}
.outer-footer a{
    color: rgb(9, 1, 32);
    text-decoration: none;
    cursor: pointer;
}

@media only screen and (max-width:900px) {
    .footer-left{
        margin: 2rem 0;
        width: 100vw;
    }
    .footer-list{
        width: 80vw;
    margin: auto;
    margin-bottom: 50px;
    }
    .footer-text{
        width: 85vw;
        margin: 2rem 0;
    }
    .footer-img{
        display: flex;
        justify-content: left;
        width: 100vw;
        margin-bottom: 25px;
    }
    .btn-footer{
        padding: 1rem 5rem;
    }
    .outer-footer{
        padding-bottom: 0rem;
    }
    .lower-footer{
        height: 5vh;
    }
}
