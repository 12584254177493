.text-area-to-report{
    outline: 1px solid #ccc;
    padding: 10px;
    border-radius: 10px;
    width: 100%;
    
}
.report-container{
    box-shadow: 2px 2px 5px 5px solid black;
    background-color: rgb(225, 225, 225);
    padding: 2rem;
    border-radius: 4px;
    position: absolute;
    width: 40vw;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 700px;
}

.report-button{
    margin-top: 10px;
}

@media only screen and (max-width: 600px) {
    .report-container{
        box-shadow: 2px 2px 5px 5px solid black;
        background-color: rgb(225, 225, 225);
        padding: 2rem;
        border-radius: 4px;
        position: absolute;
        width: 90vw;
        top: 50vh;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 700px;
    }
    .report-button{
        margin-top: -52px;
    }
    
  }

.report-container h2{
    margin-bottom: 10px;
}
.gap{
    height: 10px;
}
.overlay{
    height: 100%;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.458);
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}