.otpLogin {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: black;
  padding: 7% 9.5%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}
.otpLogin > span {
  margin: 10px 0px 10px 0px;
}

.otpLogin > div {
  display: flex;
}
.otpLogin > div > p {
  margin: 14px 7px;
}
.otpLogin > div > a {
  margin: 14px 7px;
}
.otpLogin > div > a {
  text-decoration: underline;
  color: black;
}
.otpLogin > div > a:hover {
  color: #23527c;
}

.otpLogin > div > p {
  margin-left: 0px;
}

.resendOTP {
  margin: 30px 0px;
  color: black;
  text-decoration: underline;
}
.submitbtn {
  width: 22vmax;
  height: 55px;
  background: #113359;
  border-radius: 5px;
  color: white;
  margin: 14px 0;
}

form > div {
  display: flex;
}
form > div > input {
  width: 50px;
  height: 55px;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 14px 8px;
  text-align: center;
  caret-color: transparent;
}
form > div > input:first-child {
  margin-left: 0;
}

.otpFooter {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 87px;
}
.otpFooter > img {
  width: 10vmax;
  max-width: 24px;
}
.otpFooter > h1 {
  height: 17px;
  width: 298px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  margin-top: 18.75px;
  margin-bottom: 16px;
}
.otpFooter > p {
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: #646060;
  width: 438px;
  height: 85px;
}

.otpInput::-webkit-outer-spin-button,
.otpInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.otpInput[type=number] {
  -moz-appearance: textfield;
}