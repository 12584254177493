.bold{
   font-weight: bold;
   margin-top: 50px;
   margin-bottom: 25px;
   list-style: none;
}
.terms-main-container{
   margin: auto;
   margin-top: 100px;
   margin-bottom: 100px;
   width: 90vw;
   display: block;
   max-width: 1800px;
   
}
.terms-main-container ul li{
   list-style: none;
}
.terms-main-container h4{
   color: blueviolet;
   font-weight: bold;
}