.loginHeading {
  /* height: 68px; */
  font-weight: 600;
  font-size: 28px;
  line-height: 34px;
}
.loginHeading > span {
  color: #646060;
}

@media (max-width: 769px) {
  .loginHeading {
    text-align: center;
    width: 100%;
  }
}
