.task-input{
   display: flex;
   flex-direction: column;
}
#subject{
   width: 60%;
   text-align: left;
   padding-left: 10px;
}
.note_title{
   border: 1px solid rgb(187, 187, 187);
   padding: 10px;
   border-radius: 7px;
   margin: 10px 0px;
   margin-left: 10px;
}

.task-input p{
   color: #5D44DE;
   font-weight: bold;
   margin-left: 10px;
   font-size: 1.5rem;
}
.task-form{
   margin-top: 25px;
}
#note{
   width: 60%;
   text-align: left;
   padding-left: 10px;
   height: 150px;
   min-width: 300px;
   padding: 10px;
   border: 1px solid #dedede;
   border-radius: 5px;
   margin-bottom: 25px;
   margin-top: 10px;
   margin-left: 10px;
}
#saveNote{
   background-color: #5D44DE;
   color: white;
   font-weight: 600;
   text-align: center;
   width: 30%;
   min-width: 100px;
   padding: 20px;
   border-radius: 10px;
   margin-left: 10px;
   transition-duration: 1s;
}
#saveNote:hover{
   transform: scale(1.02);
}
.saved-notes{
   border-bottom: 1px solid #5D44DE;
}
.notes-area{
   display: flex;
   flex-wrap: wrap;
   margin-top: 30px;
}
.saved-notes-list1{
   width: 80%;
   min-width: 300px;
}
.controls{
   width: 18%;
   display: flex;
   position: absolute;
   right: 0%;
}
.controls button{
   border-radius: 5px;
   padding: 7px 15px;
   margin: 3px;
   color: white;
   font-weight: 500;
   height: 40px;
}
.notes-edit{
   background-color: #FB9C45;
}
.notes-delete{
   background-color: #FB6645;
}
.notes-download{
   background-color: #01ab56;
}
.saved-notes-list{
   display: flex;
   margin-bottom: 50px;
}
.notes-heading{
   font-weight: 600;
   font-size: 1.5rem;
}
.notes-content p{
   margin-top: 20px;
   width: 90%;
}
.note-img img{
   width: 25px;
}
.notes-content{
   margin-left: 20px;
}
@media screen and (max-width: 800px) {
   #subject{
      width: 90%;
   }
   #saveNote {
      width: 50%;
   }
 }