.box-date{
   padding: 10px 20px;
   border-radius: 20px;
   background-color: #E9F5FE;
   display: flex;
   width: 200px;
   
}
.box-date h1{
   font-size: large;
   font-weight: bold;
   margin-right: 30px;
}
.streaks{
   font-size: 30px;
   font-weight: 600;
}
.bottom-conatiner{
   display: flex;
   
}
.calenderContainer{
   width: 60%;
   
}
.performance-container{
   width: 30%;
   
}
.calenderContainer h1{
   font-weight: bold;
   font-size: 25px;
}
.calendar-Container{
   margin-top: 50px;
}
.react-calendar{
   border-radius: 25px;
   padding: 25px;
   width: 80% !important;
}
.dataContainer{
   margin-top: 10vh;
}
@media screen and (max-width: 1000px) {
   .bottom-conatiner {
      display: flex;
      flex-wrap: wrap;
  }
  .calenderContainer {
   width: 100%;
}
 }