.login {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: black;
  padding: 7% 9.5%;
  font-family: "Inter";
  font-style: normal;
}

.login > span {
  margin: 30px 0px 10px 0px;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.login > form {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.login > form > input {
  width: 100%;
  width: 100%;
  height: 55px;
  max-width: 338px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  /* border: 1px solid #303030; */
  border-radius: 5px;
  margin: 10px 0px;
  padding-left: 10px;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.passLink {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  margin: 10px 0;
  font-weight: 600;
  max-width: 338px;
  text-align: end;
  color: #11325b;
}
.loginSubmitBtn {
  display: flex;
  align-items: center;
  width: 100%;
  height: 55px;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin: 10px 0px;
  padding-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  max-width: 338px;
  color: white;
  justify-content: center;
}

.newAccount {
  margin: 12px 0px;
  text-align: center;
  text-decoration: underline;
  color: black;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
}

.gLogin {
  display: flex;
  flex-direction: column;
}

.loginBtn {
  display: flex;
  align-items: center;
  /* width: 22vmax; */
  height: 55px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  border-radius: 5px;
  margin: 10px 0px;
  padding-left: 10px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: black;
  text-align: left;
  max-width: 338px;
}

.loginBtn,
.loginSubmitBtn {
  cursor: pointer;
  transition-duration: 0.5s;
}

.loginBtn:hover,
.loginSubmitBtn:hover {
  scale: 1.1;
  transition-duration: 0.5s;
}

.loginBtn > img {
  padding: 0 10px;
}

@media (max-width: 769px) {
  .login {
    width: 100%;
    max-width: 100%;
    /* text-align: center; */
    align-items: center;
  }
}
