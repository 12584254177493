body {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
  background-color: #ffffff;
  /* opacity: 0.8; */
  background-image: radial-gradient(#f79a45 0.5px, transparent 0.5px),
    radial-gradient(#f79a45 0.5px, #ffffff 0.5px);
  background-size: 20px 20px;
  background-position: 0 0, 10px 10px;
}

section {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  padding: 30px 0;
  background-image: linear-gradient(
      rgba(255, 0, 0, 0),
      rgba(255, 0, 0, 0),
      rgba(255, 0, 0, 0),
      #ffffff
    ),
    url(../../../assets/Pricing/pricing-blob.svg);
  background-position: center;
  padding-bottom: 200px;
}
.pricing-section{
  text-align: center;
}
#price-main-heading-id{
  font-size: 3rem;
  color: #ff0000;
  margin-top: 30px;
  margin-bottom: 30px;
  font-weight: bold;
}


.price-main-heading{
  margin-bottom: 10px;
  font-size: 15px;
}
.note-container h4{
  font-size: 2rem;
  margin-top: 60px;
  font-weight: bold;
  margin-bottom: 20px;
}

.columns {
  float: left;
  width: 15%;
  background-color: white;
}
.columns1 {
  float: left;
  width: 25%;
  background-color: white;
}

.price {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  margin-left: 5px;
}
.price1 {
  list-style-type: none;
  border: 1px solid #eee;
  margin: 0;
  padding: 0;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.price:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}
.price1:hover {
  box-shadow: 0 8px 12px 0 rgba(0,0,0,0.2)
}

.price .header {
  background-color: #111;
  color: white;
  font-size: 18px;
}

.price1 .header1 {
background: linear-gradient(270deg, #E1E1E1 -0.52%, #9648AD -0.51%, #1D77FF 100.52%);
color: white;
font-size: 18px;
}
.price li {
  border-bottom: 1px solid #eee;
  padding: 13px;
  text-align: center;
}

.price1 li {
  border-bottom: 1px solid #eee;
  padding: 13px;
  text-align: center;
}

.price .grey {
  background-color: #eee;
  font-size: 20px;
}
.head-text-li{
  color: #9648AD;
  font-weight: bold;
}
.price-final{
  color: #04aa6d;
  font-size: 20px;
  font-weight: bold;
}

.button {
  border: none;
  color: white;
  padding: 10px 25px;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
}

@media only screen and (max-width: 600px) {
  .columns {
    width: 100%;
  }
  .columns1 {
    width: 100%;
  }
}

#strike {
  font-size: 2.1rem;
}
.note-container ul {
  padding-left: 0px;
}
.container1{
  max-width: 1200px;
  margin: auto;
}
.row1{
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.pricing-faq{
  font-weight: 600;
  font-size: 25px;
  color: #9648AD;
  text-align: center;
}

.faq-pricing-container{
  position: relative;
  bottom: 100px;
}