.signUp {
  width: 45%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  color: black;
  padding: 7% 9.5%;

  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  font-family: "Inter";
  font-style: normal;
}

.signUp > span {
  margin: 30px 0px 10px 0px;
}

.signUp > form {
  display: flex;
  flex-direction: column;
}

.signUp > form > input {
  width: 100%;
  height: 55px;
  max-width: 338px;
  background: #ffffff;
  border: 1px solid #cdcdcd;
  /* border: 1px solid #303030; */
  border-radius: 5px;
  margin: 10px 0px;
  padding-left: 10px;
}

.communicationDetails {
  display: flex;
  width: 100%;
  max-width: 338px;
}

.communicationDetails > div {
  width: 30%;
  max-width: 100px;
  height: 55px;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 5px;
  margin: 16px 13px 14px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
.communicationDetails > div > img {
  max-width: 24px;
  max-height: 24px;
  margin: 5px;
}

#PhnNumber {
  width: 100%;
  max-width: 224px;
  margin-top: 16px;
  text-align: start;
  padding: 10px;
  margin-left: 0px;
  margin-right: 0px;
}
.communicationDetails > input::placeholder {
  font-weight: 400;
}

.signUp > form > input::placeholder {
  font-weight: 400;
}

.signUp > form > span {
  margin: 14px 0px;
}

@media (max-width: 769px) {
  .signUp {
    width: 100%;
    align-items: center;
  }
}
