.sign-up {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;
  text-decoration-line: underline;

  color: #ffffff;
}

.upper-nav {
  background: linear-gradient(
    270deg,
    #e1e1e1 -0.52%,
    #9648ad -0.51%,
    #1d77ff 100.52%
  );
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  /* gap: 3rem; */
  /* height: 5vh; */
  padding: 2vh;
}

.upper-nav-text {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  margin: 0.5rem 2rem;

  text-align: center;

  color: #ffffff;
}

.lower-nav {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 0px 4rem;
  margin: 1rem 0;
  margin-bottom: 0px;
}

.nav-ul {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.nav-li {
  margin: 0px 1.5rem;

  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #000000;
  text-decoration: none;
}

.logo-img {
  height: 4vw;
  max-width: 400px;
  cursor: pointer;
}

.btn-nav {
  background: #5d44de;
  border-radius: 5px;
  width: 11vw;
  height: 3vw;
  border: none;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #f5f5f5;
}
.lower-nav ul li a {
  cursor: pointer;
  color: rgb(12, 4, 31);
  text-decoration: none;
}

.hamburger {
  width: 100vw;
  padding: 2rem 2rem;
  display: none;
  align-items: center;
  justify-content: end;
}

.hamburger svg {
  cursor: pointer;
}

.mobile {
  position: absolute;
  left: 100vw;
  display: none;
  height: 100vh;
  width: 50vw;
  overflow: hidden;
  background-color: white;
  z-index: 100;
  transition-duration: 0.5s;
  background: url(../../assets/Pricing/blob-2.svg),
    linear-gradient(270deg, #e1e1e1 -0.52%, #9548ad -0.51%, #1d77ff 100.52%);
  /* background-image: ; */
  background-position: center;
  text-align: left;
  background-size: cover;
  background-blend-mode: color;
  padding: 0;
}

.mobi {
  margin: 4rem 0;
  font-weight: bold;
  font-size: 15px;
  text-align: left;
  padding: 0rem 2rem;
}

.mobi a {
  color: white;
}

#l {
  display: none;
}

@media only screen and (max-width: 900px) {
  .lower-nav {
    display: none;
  }
  #l {
    display: block;
    position: absolute;
    left: 5vw;
    height: 5vh;
    width: 25vw;
    top: 5px;
  }
  .hamburger {
    position: relative;
    display: flex;
  }
}
